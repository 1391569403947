import React from "react";
// import VideoPlayer from "react-background-video-player";
import Img from "gatsby-image";
import Button from "../../../UI/Button";
import useLanguage from "../../../Hooks/useLanguage";
import AboutBg from "../../../../video/aboutbg.mp4";

// import AboutBgm from "../bg/aboutbg.webm";

const ReactMarkdown = require("react-markdown");

const AboutSection = ({ dataAboutSection }) => {
  const langToggle = useLanguage;

  return (
    <section className="about-section">
      <Img
        fluid={dataAboutSection.image.childImageSharp.fluid}
        className="about-section_img"
      />

      <video
        className="video-section_img"
        src={AboutBg}
        playsInline=""
        webkit-playsinline=""
        autoPlay={true}
        muted={true}
        loop={true}
      ></video>

      <div className="about-section_info">
        <h3 className="h3">
          {langToggle(
            dataAboutSection.title_ua,
            dataAboutSection.title_ru,
            dataAboutSection.title_en
          )}
        </h3>
        <ReactMarkdown
          source={langToggle(
            dataAboutSection.description_ua,
            dataAboutSection.description_ru,
            dataAboutSection.description_en
          )}
          className="about-section_text"
        />

        <div className="button-wrapper">
          <Button title="More" to="/about-company/" />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
