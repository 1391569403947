import React from "react";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import LiquidIcon from "../../svg/drop-of-liquid.svg";

const Button = ({ title, to }) => {
  return (
    <Link to={to} className="button-link">
      <span className="button-link_title">
        <Trans>{title}</Trans>
      </span>
      <i className="liquid-icon">
        <LiquidIcon />
      </i>
    </Link>
  );
};

export default Button;
