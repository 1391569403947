import React from "react";
// import Button from "../../../UI/Button";
import { Link } from "gatsby-plugin-react-i18next";
import useLanguage from "../../../Hooks/useLanguage";

const ReactMarkdown = require("react-markdown");

const ActivitySection = ({ dataActivity }) => {
  const langToggle = useLanguage;
  return (
    <section className="activity-section">
      <div className="container-fluid">
        <div className="section-header">
          <ReactMarkdown
            source={langToggle(
              dataActivity.title_ua,
              dataActivity.title_ru,
              dataActivity.title_en
            )}
            className="h3"
          />
        </div>
        <div className="activity-items">
          <div className="row">
            {dataActivity.Services.map((item, index) => {
              return (
                <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-12">
                  <Link to={item.slug}>
                    <div className="activity-item">
                      <i className="activity-item_icon">
                        <img src={item.icon.publicURL} alt="icon" />
                      </i>
                      <span className="activity-item_title">
                        {langToggle(
                          item.title_ua,
                          item.title_ru,
                          item.title_en
                        )}
                      </span>
                      <p className="activity-item_content">
                        {langToggle(
                          item.description_ua,
                          item.description_ru,
                          item.description_en
                        )}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="section-footer">
          <Button title="Дізнатися більше" to="/" />
        </div> */}
      </div>
    </section>
  );
};

export default ActivitySection;
