import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HomeMainScreen from "../components/Pages/Home/HomeMainScreen";
import "../styles/style.sass";
import ActivitySection from "../components/Pages/Home/ActivitySection/ActivitySection";
import FuelSection from "../components/Pages/Home/FuelSection/FuelSection";
import AboutSection from "../components/Pages/Home/AboutSection/AboutSection";
import AdvantageSection from "../components/Pages/Home/AdvantageSection/AdvantageSection";
import PartnerSection from "../components/Pages/Home/PartnerSection/PartnerSection";
import TagLineSection from "../components/Pages/Home/TagLineSection/TagLineSection";
import useLanguage from "../components/Hooks/useLanguage";
// import Preloader from "../components/HeaderSaidBar/Preloader/Preloader";

const IndexPage = ({ data }) => {
  const { t } = useTranslation();
  const langToggle = useLanguage;
  const seo = data.strapiHome.seo;
  return (
    <Layout>
      <SEO
        title={t("Home")}
        description={langToggle(
          seo.seo_description_ua,
          seo.seo_description_ru,
          seo.seo_description_en
        )}
        keywords={[
          langToggle(
            seo.seo_keywords_ua,
            seo.seo_keywords_ru,
            seo.seo_keywords_en
          ),
        ]}
      />
      {/* <Preloader /> */}
      <HomeMainScreen dataMainScreen={data.strapiHome.MainScreen} />
      <ActivitySection dataActivity={data.strapiHome.Services_Title} />
      <FuelSection
        dataFuel={data.strapiHome?.Fuel_Title}
        dataFuelType={data.strapiHome?.Type_Fuel}
      />
      <AboutSection dataAboutSection={data.strapiHome.About} />
      <AdvantageSection
        dataAdvantage={data.strapiHome.Advantages_Title}
        dataAdvantageIcons={data.strapiHome.Advantages}
      />
      <PartnerSection dataPartners={data.strapiHome.Partners_Title} />
      <TagLineSection
        dataTagLine={data.strapiHome.Tagline}
        dataTagLineImage={data.strapiHome.tagline_image}
      />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query MyQuery {
    strapiHome {
      seo {
        seo_description_ua
        seo_description_ru
        seo_description_en
        seo_keywords_ua
        seo_keywords_ru
        seo_keywords_en
      }
      MainScreen {
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title_ua
        title_ru
        title_en
      }
      Services_Title {
        title_ua
        title_ru
        title_en
        Services {
          title_ua
          title_ru
          title_en
          description_ua
          description_ru
          description_en
          slug
          icon {
            publicURL
          }
        }
      }
      Fuel_Title {
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Type_Fuel {
        title_ua
        title_ru
        title_en
        icon {
          publicURL
        }
      }
      About {
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Advantages_Title {
        title_ua
        title_ru
        title_en
      }
      Advantages {
        title_ua
        title_ru
        title_en
        icon {
          publicURL
        }
      }
      Partners_Title {
        title_ua
        title_ru
        title_en
        Partners {
          icon {
            publicURL
          }
        }
      }
      Tagline {
        title_ua
        title_ru
        title_en
      }
      tagline_image {
        image {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
