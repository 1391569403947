import React from "react";
import Img from "gatsby-image";
import TagLineBg from "../../../../video/tagline.mp4";

const TagLineSection = ({ dataTagLine, dataTagLineImage }) => {
  return (
    <section className="tagline-section">
      {dataTagLineImage.map((img, index) => {
        return (
          <Img
            key={index}
            fluid={img.image.childImageSharp.fluid}
            className="tagline-background"
          />
        );
      })}

      <video
        className="tagline-video_bg"
        src={TagLineBg}
        playsInline=""
        webkit-playsinline=""
        autoPlay={true}
        muted={true}
        loop={true}
      ></video>

      <div className="tagline-wrapper">
        <h3 className="h3">{dataTagLine.title_ua}</h3>
      </div>
    </section>
  );
};

export default TagLineSection;
