import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.scss";
// import ArrowLeft from "../../../../svg/left-arrow.svg";
// import ArrowRight from "../../../../svg/right-arrow.svg";
import useLanguage from "../../../Hooks/useLanguage";

const PartnerSection = ({ dataPartners }) => {
  const langToggle = useLanguage;

  // function SampleNextArrow(props) {
  //   const { className, onClick } = props;
  //   return (
  //     <button className={className} onClick={onClick}>
  //       Next
  //       <ArrowRight />
  //     </button>
  //   );
  // }

  // function SamplePrevArrow(props) {
  //   const { className, onClick } = props;
  //   return (
  //     <button className={className} onClick={onClick}>
  //       Prev
  //       <ArrowLeft />
  //     </button>
  //   );
  // }

  // var settings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: true,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   //autoplay: true,
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 576,
  //       settings: { slidesToShow: 2, slidesToScroll: 2 },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: { slidesToShow: 2, slidesToScroll: 2 },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: { slidesToShow: 3, slidesToScroll: 3 },
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: { slidesToShow: 3, slidesToScroll: 3 },
  //     },
  //   ],
  // };

  // const slider = useRef(null);

  return (
    <section className="partners-section">
      <div className="container-fluid">
        <div className="section-header">
          <h3 className="h3">
            {langToggle(
              dataPartners.title_ua,
              dataPartners.title_ru,
              dataPartners.title_en
            )}
          </h3>
        </div>
        <div className="slider-wrapper">
          {dataPartners.Partners.map((item, index) => {
            return (
              <div key={index} className="slider-item">
                <img
                  src={item?.icon?.publicURL}
                  alt="slider-icon"
                  className="slider-item_icon"
                />
              </div>
            );
          })}
        </div>
        {/* <div className="slider-wrapper">
          <Slider ref={slider} {...settings}>
            {dataPartners.Partners.map((slide, index) => {
              return (
                <div key={index} className="slide">
                  <figure className="slide-icon">
                    <img
                      src={slide.icon.publicURL}
                      alt=""
                      className="partners-icon"
                    />
                  </figure>
                </div>
              );
            })}
          </Slider>
        </div> */}
      </div>
    </section>
  );
};

export default PartnerSection;
