import React from "react";
import Img from "gatsby-image";
import { scroller } from "react-scroll";
import Drop from "../../../svg/drop.svg";
import useLanguage from "../../Hooks/useLanguage";
import Bgm from "../../../video/bg.mp4";

const HomeMainScreen = ({ dataMainScreen }) => {
  const langToggle = useLanguage;
  function scrollToSection() {
    scroller.scrollTo("activity-section", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <section className="home-mainscreen">
      <Img
        fluid={dataMainScreen.image.childImageSharp.fluid}
        className="main-screen_bg"
      />

      <video
        className="video-screen_bg"
        src={Bgm}
        playsInline=""
        webkit-playsinline=""
        autoPlay={true}
        muted={true}
        loop={true}
      ></video>

      <div className="main-screen_center">
        <h1 className="h1">
          {langToggle(
            dataMainScreen.title_ua,
            dataMainScreen.title_ru,
            dataMainScreen.title_en
          )}
        </h1>
      </div>

      <button className="scroll-down" onClick={scrollToSection}>
        <Drop />
      </button>
    </section>
  );
};

export default HomeMainScreen;
