import React from "react";
// import Button from "../../../UI/Button";
import Img from "gatsby-image";
import useLanguage from "../../../Hooks/useLanguage";
const ReactMarkdown = require("react-markdown");

const FuelSection = ({ dataFuel, dataFuelType }) => {
  const langToggle = useLanguage;
  return (
    <section className="fuel-section">
      <Img
        fluid={dataFuel.image.childImageSharp.fluid}
        className="fuel-section_img"
      />
      <div className="container-fluid">
        <div className="section-header">
          <h3 className="h3">
            {langToggle(
              dataFuel.title_ua,
              dataFuel.title_ru,
              dataFuel.title_en
            )}
          </h3>
          <ReactMarkdown
            source={langToggle(
              dataFuel.description_ua,
              dataFuel.description_ru,
              dataFuel.description_en
            )}
            className="description"
          />
        </div>

        <div className="fuel-items">
          {dataFuelType.map((item, index) => {
            return (
              <div key={index} className="fuel-item">
                <i className="fuel-icon">
                  <img src={item.icon.publicURL} alt="icon" />
                </i>
                <span className="fuel-title">
                  {langToggle(item.title_ua, item.title_ru, item.title_en)}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className="section-footer">
        <Button title="Дізнатися більше" to="/" />
      </div> */}
    </section>
  );
};

export default FuelSection;
