import React from "react";
import useLanguage from "../../../Hooks/useLanguage";
const ReactMarkdown = require("react-markdown");

const AdvantageSection = ({ dataAdvantage, dataAdvantageIcons }) => {
  const langToggle = useLanguage;
  return (
    <section className="advantage-section">
      <div className="container-fluid">
        <div className="section-header">
          <h3 className="h3">
            {langToggle(
              dataAdvantage.title_ua,
              dataAdvantage.title_ru,
              dataAdvantage.title_en
            )}
          </h3>
        </div>
        <div className="advantages-items">
          {dataAdvantageIcons.map((item, index) => {
            return (
              <div key={index} className="advantages-item">
                <img
                  src={item.icon.publicURL}
                  alt="advantages-icon"
                  className="advantages-item_icon"
                />
                <ReactMarkdown
                  source={langToggle(
                    item.title_ua,
                    item.title_ru,
                    item.title_en
                  )}
                  className="advantages-item_title"
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;
